import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  changeLibraryTab,
  CurrentLibrarySection,
} from "../../../redux/pages/librarySlice";
import { Tab } from "@mui/material";

export interface ILibraryTab {
  label: string;
  value?: CurrentLibrarySection;
}

export const LibraryTab = ({ label, value }: ILibraryTab) => {
  const theme = useSelector((state: RootState) => state.theme);
  const dispatch = useDispatch();
  const currentSection = useSelector(
    (state: RootState) => state.library.currentLibrarySection
  );
  const isTabSelected = currentSection === value;

  const handleClickTab = () => {
    dispatch(changeLibraryTab(value));
  };

  return (
    <Tab
      sx={{
        textTransform: "none",
        color: isTabSelected
          ? theme.componentColors.primary.primary
          : theme.componentColors.surface.onSurfaceVariant,
        opacity: "1",
        backgroundImage: "none",
      }}
      value={value}
      label={label}
      onClick={handleClickTab}
    />
  );
};
