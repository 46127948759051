import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import DraggableSlider from "../../../global/sliders/DraggableSlider";
import { MaterialCard } from "../../../explore/material/cards/MaterialCard";
import { MOCK_ARRAY_5 } from "../../../../lib/constants";
import { MaterialCardLoading } from "../../../explore/material/cards/MaterialCardLoading";
import Link from "next/link";
import { TextButton } from "../../../global/buttons/material/textButton/TextButton";
import { setTabSection } from "../../../../redux/pages/exploreSlice";
import { ListingsFromApi } from "../../../../interfaces/training-plan/ListingsFromApi";

export interface HomepageListingSliderProps {
  listings: ListingsFromApi;
  title: string;
  subtitle?: string;
  url: string;
  exploreTabSection: number;
}

export const HomepageListingSlider = ({
  listings,
  title,
  subtitle,
  url = "/",
  exploreTabSection,
}: HomepageListingSliderProps) => {
  const theme = useSelector((state: RootState) => state.theme);
  const dispatch = useDispatch();

  return (
    <Stack className="flex flex-col space-y-[13px] lg:space-y-[26px]">
      <Stack direction="row" className="flex  items-center justify-between">
        <Stack>
          <Typography
            variant="h2"
            className="text-3xl font-medium"
            sx={{
              color: theme.componentColors.surface.onSurfaceDetachable,
            }}
          >
            {title}
          </Typography>
          {subtitle ? (
            <Typography
              variant="h3"
              className="text-[15px] font-normal"
              sx={{
                color: theme.componentColors.surface.onSurfaceVariant,
              }}
            >
              {subtitle}
            </Typography>
          ) : (
            <Typography
              variant="h3"
              className="text-[15px] font-normal"
              sx={{
                color: theme.componentColors.surface.onSurfaceVariant,
              }}
            >
              Train like a <span className="font-medium">Pro</span>
            </Typography>
          )}
        </Stack>

        <Link
          href={url}
          onClick={() => {
            dispatch(setTabSection(exploreTabSection));
          }}
        >
          <TextButton text="See all" />
        </Link>
      </Stack>

      <DraggableSlider
        data={listings?.rows ? listings.rows : MOCK_ARRAY_5}
        Component={listings?.rows ? MaterialCard : MaterialCardLoading}
        showTitle={false}
        halfPositionArrows
      />
    </Stack>
  );
};
