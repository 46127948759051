import React from "react";
import { cn } from "../../../helpers/cn";

type LandingHeroContentProps = React.ComponentProps<"div">;

export const LandingHeroContent = ({
  children,
  className,
}: LandingHeroContentProps) => {
  return (
    <div
      className={cn(
        "flex flex-col space-y-10 before:space-y-10 py-10  rounded-2xl items-stretch sm:items-center md_2:items-start",
        className
      )}
    >
      {children}
    </div>
  );
};

export const LandingHeroTextsActionsContainer = ({
  children,
  className,
}: LandingHeroContentProps) => {
  return (
    <div
      className={cn("flex flex-col items-start gap-10 z-20 mx-auto", className)}
    >
      {children}
    </div>
  );
};

export const LandingHeroTexts = ({
  children,
  className,
}: LandingHeroContentProps) => {
  return (
    <div className={cn("flex flex-col gap-6 xl:w-[36.5rem]", className)}>
      {children}
    </div>
  );
};
