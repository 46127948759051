import React from "react";
import { RootState } from "../../../../../../redux/store";
import { useSelector } from "react-redux";

export const FilterDivider = () => {
  const theme = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <div
      style={{
        backgroundColor: theme.surface.onSurfaceDetachable,
      }}
      className="w-[2px] h-[14px] rounded-full"
    />
  );
};
