import { useDispatch, useSelector } from "react-redux";
import { DefaultModal } from "../../../global/modals/material/DefaultModal";
import { RootState } from "../../../../redux/store";
import {
  setIsOpenOnboardingModal,
  setShowCancelModal,
  setUserData,
} from "../../../../redux/onboardingSlice";
import { OnboardingHeading } from "../onboardingHeading/OnboardingHeading";
import { useEffect } from "react";
import useUser from "../../../../hooks/user/useUser";
import { getUserData } from "../../../../lib/generic";
import * as Sentry from "@sentry/nextjs";
import { OnboardingSports } from "../onboardingSports/OnboardingSports";
import { OnboardingObjectives } from "../onboardingObjectives/OnboardingObjectives";
import { OnboardingEquipments } from "../onboardingEquipments/OnboardingEquipments";
import { OnboardingDetails } from "../onboardingDetails/OnboardingDetails";
import { OnboardingInjuries } from "../onboardingInjuries/OnboardingInjuries";
import { OnboardingProfileImage } from "../onboardingProfileImage/OnboardingProfileImage";
import { CancelOnboardingModal } from "../cancelOnboardingModal/CancelOnboardingModal";

export const OnboardingModal = () => {
  const onboardingRedux = useSelector(
    (state: RootState) => state.onboardingSlice
  );
  const dispatch = useDispatch();
  const { user } = useUser();

  useEffect(() => {
    user?.token &&
      (async function getInitialData() {
        return getUserData(user).then((userResponse) => {
          dispatch(setUserData(userResponse));
        });
      })().catch((error: Error) => {
        Sentry.captureException({
          category: "profile-sports",
          message: "error on getInitialData",
          data: error,
        });
      });
  }, [user, dispatch]);

  return (
    <>
      <DefaultModal
        onClose={() => {
          if (onboardingRedux.isEditing) {
            dispatch(setIsOpenOnboardingModal(false));

            return;
          }
          dispatch(setShowCancelModal(true));
        }}
        open={onboardingRedux.isOpenOnboardingModal}
      >
        <div className={`p-5 lg:w-[65vw] 3xl:w-[50vw] space-y-[24px]`}>
          <OnboardingHeading />
          {onboardingRedux.step === 1 && <OnboardingSports />}
          {onboardingRedux.step === 2 && <OnboardingObjectives />}
          {onboardingRedux.step === 3 && <OnboardingEquipments />}
          {onboardingRedux.step === 4 && <OnboardingInjuries />}
          {onboardingRedux.step === 5 && <OnboardingDetails />}
          {onboardingRedux.step === 6 && <OnboardingProfileImage />}
        </div>
      </DefaultModal>
      <CancelOnboardingModal />
    </>
  );
};
