import React, { useRef } from "react";
import { useHeroImagesTranslate } from "../../../../hooks/homepage/useHeroImagesTranslate";

type LandingHeroImagesWebRootProps = React.ComponentProps<"div">;

export const LandingHeroSectionContext = React.createContext({
  translatePixels: 0,
});

export const LandingHeroImagesWebRoot = ({
  children,
}: LandingHeroImagesWebRootProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [translateYPhone] = useHeroImagesTranslate({ ref: containerRef });

  return (
    <LandingHeroSectionContext.Provider
      value={{ translatePixels: translateYPhone }}
    >
      <div ref={containerRef} className="space-x-10 relative hidden md_2:flex">
        {children}
      </div>
    </LandingHeroSectionContext.Provider>
  );
};
