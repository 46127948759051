import { CardMedia } from "@mui/material";
import useImageSize from "../../../../../hooks/global/useImageSize";
import { IExploreCoachCardCoverImage } from "../../../../../interfaces/team/material/cards/exploreCoachCard/IExploreCoachCardCoverImage";

export const ExploreCoachCardCoverImage = ({
  imageUrl,
}: IExploreCoachCardCoverImage) => {
  const resizedImage = useImageSize({
    imageUrl: imageUrl,
    size: 500,
    isCover: true,
    originalFormat: true,
  })[0];

  return (
    <CardMedia className="rounded-[8px] aspect-video" image={resizedImage} />
  );
};
