import React, { useContext } from "react";
import { cn } from "../../../../helpers/cn";
import { LandingHeroSectionContext } from "./LandingHeroImagesWebRoot";

type LandingHeroImageProps = React.ComponentProps<"img"> & {
  goDown?: boolean;
};

export const LandingHeroImage = ({
  src,
  alt,
  width,
  height,
  className,
  goDown = true,
}: LandingHeroImageProps) => {
  const { translatePixels } = useContext(LandingHeroSectionContext);

  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      decoding="async"
      className={cn(
        "w-full h-auto shadow-phone rounded-[2rem] lg:rounded-[3rem] 2xl:rounded-[4rem] object-contain",
        className
      )}
      style={{
        transform: goDown
          ? `translateY(${translatePixels}px)`
          : `translateY(-${translatePixels}px)`,
      }}
    />
  );
};
