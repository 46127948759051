import { Typography } from "@mui/material";
import React from "react";
import { cn } from "../../../helpers/cn";

type LandingHeroSubtitleProps = React.ComponentProps<"h2">;

export const LandingHeroSubtitle = ({
  children,
  className,
}: LandingHeroSubtitleProps) => {
  return (
    <Typography
      variant="h2"
      className={cn(
        "text-base md_2:text-xl lg:text-2xl text-center md_2:text-left",
        className
      )}
    >
      {children}
    </Typography>
  );
};
