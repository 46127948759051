import React from "react";
import { analyticsFunction } from "../../../lib/dataAnalytics/eventListener";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { cn } from "../../../helpers/cn";

interface IMobileStores extends React.ComponentProps<"div"> {
  width?: boolean | number;
  section?: boolean | number;
  alwaysColumnDirection?: boolean;
  useWhiteIcons?: boolean;
}

function MobileStores({
  width = false,
  section = false,
  alwaysColumnDirection = false,
  useWhiteIcons = false,
  className,
}: IMobileStores) {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <div
      className={cn(
        alwaysColumnDirection
          ? "flex flex-col space-y-4"
          : "flex flex-col space-y-7 lg:flex-row lg:space-y-0 lg:space-x-7",
        className
      )}
    >
      {useWhiteIcons ? (
        <>
          <a
            href="https://apps.apple.com/ie/app/pod1um/id1562663063"
            target="_blank"
          >
            <img
              src="/images/apple-white-icon.svg"
              alt="App Store"
              className={`my-auto cursor-pointer`}
              onClick={() => {
                analyticsFunction({
                  event: "select_content",
                  content_type: "android",
                  item_id: `Section ${section ? section.toString() : "0"}`,
                });
              }}
            />
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=app.pod1um"
            target="_blank"
          >
            <img
              src="/images/google-white-icon.svg"
              alt="Play Store"
              className={`my-auto cursor-pointer`}
              onClick={() => {
                analyticsFunction({
                  event: "select_content",
                  content_type: "Apple",
                  item_id: `Section ${section ? section.toString() : "0"}`,
                });
              }}
            />
          </a>
        </>
      ) : (
        <>
          <Stack
            sx={{
              border: "1px solid",
              backgroundColor:
                theme.mode === "dark"
                  ? "transparent"
                  : theme.componentColors.surface.onSurface,
            }}
            className=" border rounded-lg border-gray-700 bg-gray-750 flex items-center justify-center px-2 pt-1"
          >
            <a
              href="https://play.google.com/store/apps/details?id=app.pod1um"
              target="_blank"
            >
              <img
                src="/images/google-play.svg"
                alt="Google play"
                className={`my-auto cursor-pointer ${
                  width ? `w-${width.toString()}` : "w-36"
                }`}
                onClick={() => {
                  analyticsFunction({
                    event: "select_content",
                    content_type: "android",
                    item_id: `Section ${section ? section.toString() : "0"}`,
                  });
                }}
              />
            </a>
          </Stack>

          <Stack
            sx={{
              border: "1px solid",
              backgroundColor:
                theme.mode === "dark"
                  ? "transparent"
                  : theme.componentColors.surface.onSurface,
            }}
            className="border rounded-lg border-gray-700  bg-gray-750 flex items-center justify-center px-2 pt-1"
          >
            <a
              href="https://apps.apple.com/ie/app/pod1um/id1562663063"
              target="_blank"
            >
              <img
                src="/images/app-store.svg"
                alt="Google play"
                className={`my-auto cursor-pointer ${
                  width ? `w-${width.toString()}` : "w-36"
                }`}
                onClick={() => {
                  analyticsFunction({
                    event: "select_content",
                    content_type: "Apple",
                    item_id: `Section ${section ? section.toString() : "0"}`,
                  });
                }}
              />
            </a>
          </Stack>
        </>
      )}
    </div>
  );
}

export default MobileStores;
