import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { IListingRoundedImage } from "../../../../interfaces/training-plan/material/listingRoundedImage/IListingRoundedImage";

export const ListingRoundedImage = ({
  image,
  title,
  className,
  useBorder = true,
  hover = true,
  size,
}: IListingRoundedImage) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        backgroundColor: theme.componentColors.primary.primary,
        color: theme.componentColors.primary.onPrimary,
        border: useBorder ? "4px solid" : "",
        borderColor: useBorder
          ? theme.componentColors.surface.surfContainerLowest
          : "",
        "&:hover": {
          lg: {
            borderColor: hover ? theme.componentColors.primary.primary : "",
          },
        },
      }}
      className={className + " text-xl transition-all"}
      src={image}
      title={`${title} | Pod1um`}
    >
      {title?.split(" ")[0]?.split("")[0]}
      {title?.split(" ")[1]?.split("")[0]}
    </Avatar>
  );
};
