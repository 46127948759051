import React from "react";

type LandingHeroImagesMobileRootProps = React.ComponentProps<"div">;

export const LandingHeroImagesMobileRoot = ({
  children,
}: LandingHeroImagesMobileRootProps) => {
  return (
    <div className="flex items-center justify-center md_2:hidden relative">
      {children}
    </div>
  );
};
