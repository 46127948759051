import { Paper } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { LibraryNavigationTabs } from "./libraryTabs/LibraryNavigationTabs";
import { CurrentLibrarySection } from "../../redux/pages/librarySlice";
import { LibraryTrainings } from "./libraryTrainings/LibraryTrainings";
import { LibraryCoaches } from "./libraryCoaches/LibraryCoaches";
import useUser from "../../hooks/user/useUser";
import { OnboardingModal } from "../onboarding/material/onboardingModal/OnboardingModal";

export const Library = () => {
  useUser({ redirectTo: "/login" });

  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const currentLibrarySection = useSelector(
    (state: RootState) => state.library.currentLibrarySection
  );

  return (
    <>
      <OnboardingModal />

      <Paper
        sx={{
          bgcolor: componentColors.surface.surfContainerLowest,
        }}
        className="px-2 pt-4 lg:p-6 rounded-[1.75rem]"
      >
        <LibraryNavigationTabs />

        {currentLibrarySection === CurrentLibrarySection.LISTINGS && (
          <LibraryTrainings />
        )}
        {currentLibrarySection === CurrentLibrarySection.COACHES && (
          <LibraryCoaches />
        )}
      </Paper>
    </>
  );
};
