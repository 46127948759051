import React from "react";
import DraggableSlider from "../../global/sliders/DraggableSlider";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { MaterialCard } from "../../explore/material/cards/MaterialCard";
import { MOCK_ARRAY_10 } from "../../../lib/constants";
import { MaterialCardLoading } from "../../explore/material/cards/MaterialCardLoading";
import { DefaultLoadingComponent } from "../../global/defaultLoading/DefaultLoadingComponent";

export const LibrarySavedListings = () => {
  const { bookmarkedTrainingAndWorkouts, isLoading } = useSelector(
    (state: RootState) => state.library.trainingSection
  );
  const savedListings = bookmarkedTrainingAndWorkouts.filter(
    (item) => item.type === "training"
  );

  if (isLoading) {
    return (
      <div className="space-y-3 lg:space-y-6">
        <DefaultLoadingComponent width={250} height={32} />

        <DraggableSlider
          data={MOCK_ARRAY_10}
          Component={MaterialCardLoading}
          showTitle={false}
          isLoading
        />
      </div>
    );
  }

  if (!isLoading && bookmarkedTrainingAndWorkouts.length > 0) {
    return (
      <DraggableSlider
        data={savedListings}
        Component={MaterialCard}
        showTitle={true}
        title="Bookmarked training plans & workouts"
        customSwiperId="user-bookmarked-listings"
        showTopButtons
      />
    );
  }
};
