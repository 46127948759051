import React from "react";
import { LibraryNoCoaches } from "./LibraryNoCoaches";
import { useLibraryCoachesData } from "../../../hooks/library/useLibraryCoachesData";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { LibraryUserCoaches } from "./LibraryUserCoaches";
import { LibraryFollowingCoaches } from "./LibraryFollowingCoaches";

export const LibraryCoaches = () => {
  useLibraryCoachesData();

  const libraryCoaches = useSelector(
    (state: RootState) => state.library.coachesSection
  );

  const emptyData =
    !libraryCoaches.isLoading &&
    libraryCoaches.followingCoaches.length === 0 &&
    libraryCoaches.userCoaches.length === 0;

  return (
    <div className="pt-6 ">
      {emptyData ? (
        <div className="flex items-center justify-center">
          <LibraryNoCoaches />
        </div>
      ) : (
        <>
          <LibraryUserCoaches />

          <LibraryFollowingCoaches />
        </>
      )}
    </div>
  );
};
