import useImageSize from "../../../../../hooks/global/useImageSize";
import { ListingRoundedImage } from "../../../../training-plan/material/listingRoundedImage/ListingRoundedImage";
import { IExploreCoachCardAvatarImage } from "../../../../../interfaces/team/material/cards/exploreCoachCard/IExploreCoachCardAvatarImage";

export const ExploreCoachCardAvatarImage = ({
  data,
}: IExploreCoachCardAvatarImage) => {
  const resizedImage = useImageSize({
    imageUrl: data.avatarUrl,
    size: 500,
    isCover: true,
    originalFormat: true,
  })[0];

  return (
    <ListingRoundedImage
      useBorder={false}
      hover={false}
      image={resizedImage}
      title={data.name}
      className="w-[100px] h-[100px] lg:h-[110px] lg:w-[110px]"
    />
  );
};
