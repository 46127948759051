import React from "react";
import { useLibraryTrainingsData } from "../../../hooks/library/useLibraryTrainingsData";
import { LibraryUserTrainings } from "./LibraryUserTrainings";
import { LibraryWorkouts } from "./LibraryWorkouts";
import { LibraryRecommendedTrainingPlans } from "./LibraryRecommendedTrainingPlans";
import { LibrarySavedListings } from "./LibrarySavedListings";
import { LibrarySavedResources } from "./LibrarySavedResources";
import { LibraryGetTheApp } from "../libraryGetTheApp/LibraryGetTheApp";

export const LibraryTrainings = () => {
  useLibraryTrainingsData();

  return (
    <div className="flex flex-col pt-6 space-y-6">
      <LibraryUserTrainings />

      <LibraryWorkouts />

      <LibraryGetTheApp />

      <LibraryRecommendedTrainingPlans />

      <LibrarySavedListings />

      <LibrarySavedResources />
    </div>
  );
};
