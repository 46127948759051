import React from "react";

type LandingHeroRootProps = React.ComponentProps<"div">;

export const LandingHeroRoot = ({ children }: LandingHeroRootProps) => {
  return (
    <div className="flex flex-col w-full items-center justify-center md_2:flex-row  md_2:justify-between  md_2:items-center md_2:px-0 md_2:mx-auto 2xl:max-w-[1600px] md_2:space-x-14 lg:space-x-28 md_2:mb-20 ">
      {children}
    </div>
  );
};
