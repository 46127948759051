import React, { useState } from "react";
import { CoachesFromApi } from "../../../interfaces/specialist/CoachesFromApi";
import { ListingsFromApi } from "../../../interfaces/training-plan/ListingsFromApi";
import { useCoaches } from "../../../hooks/global/useCoaches";
import { useListings } from "../../../hooks/global/useListings";
import { useWorkouts } from "../../../hooks/global/useWorkouts";
import { FilterSearchBar } from "../../global/filters/material/filterSearchBar/FilterSearchBar";
import { OnboardingModal } from "../../onboarding/material/onboardingModal/OnboardingModal";
import { AthleteHeroSection } from "./AthleteHeroSection";
import { HomepageCoachesSection } from "./HomepageCoachesSection";
import { HomepageTrainingsSlider } from "./HomepageTrainingsSection";
import { HomepageWorkoutsSection } from "./HomepageWorkoutsSection";
import { HomepageSecondSection } from "./HomepageSecondSection";

export const HomepageMaterial = () => {
  const [coaches, setCoaches] = useState<CoachesFromApi>({
    rows: null,
    count: null,
  });
  const [listings, setListings] = useState<ListingsFromApi>({
    rows: null,
    count: null,
  });
  const [workouts, setWorkouts] = useState<ListingsFromApi>({
    rows: null,
    count: null,
  });

  useCoaches({ setCoaches });
  useListings({ setListings });
  useWorkouts({ setWorkouts });

  return (
    <>
      <OnboardingModal />
      <div className="flex flex-col gap-[7rem]">
        <AthleteHeroSection />

        <HomepageSecondSection />

        <FilterSearchBar />

        <HomepageWorkoutsSection workouts={workouts} />

        <HomepageTrainingsSlider listings={listings} />

        <HomepageCoachesSection coaches={coaches} />
      </div>
    </>
  );
};
