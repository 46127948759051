import { Avatar, Box, Card, CardContent, Chip, Stack } from "@mui/material";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";

export const MaterialCardLoading = () => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  return (
    <Card
      sx={{
        backgroundColor: componentColors.outline.outlineVariant,
      }}
      className={`w-full flex flex-col animate-pulse rounded-[12px]`}
    >
      <Box
        sx={{
          borderTop: 1,
          borderColor: componentColors.surface.surfContainer,
        }}
        className="w-full h-[250px] "
      />
      <CardContent
        sx={{
          borderTop: 1,
          borderColor: componentColors.surface.surfContainer,
        }}
        className="w-full h-[50px] flex items-start  justify-between space-x-[8px]"
      >
        <Stack direction="row" spacing={"10px"}>
          <Avatar
            src={""}
            sx={{
              bgcolor: componentColors.secondary.secondaryContainer,
              color: componentColors.surface.onSurface,
            }}
          >
            {""}
          </Avatar>
          <Stack className="flex flex-col space-y-2 items-start justify-start">
            <Chip label={``} className="w-[160px] h-[12px] rounded-3xl" />
            <Chip label={``} className="w-[120px] h-[10px] rounded-3xl" />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
