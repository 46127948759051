import { Button } from "@mui/material";
import React from "react";
import { IFilterMenuButton } from "../../../../../../interfaces/globalComponents/filters/material/filterSearchBar/filterMenu/IFilterMenuButton";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";

export const FilterMenuButton = ({
  setOpenDropdown,
  anchorButtonRef,
  id,
  label,
  isOpenDropdown,
  currentFilterItems,
}: IFilterMenuButton) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Button
      variant="text"
      ref={anchorButtonRef}
      id={id}
      sx={{
        textTransform: "none",
        color: theme.componentColors.surface.onSurfaceDetachable,
        "&:hover": {
          background: "none",
        },
      }}
      className="rounded-2xl px-[10px]"
      onClick={() => setOpenDropdown((prev) => !prev)}
      startIcon={<SearchIcon />}
      endIcon={
        isOpenDropdown ? (
          <KeyboardArrowUpRoundedIcon />
        ) : (
          <KeyboardArrowDownRoundedIcon />
        )
      }
    >
      {label}{" "}
      {currentFilterItems > 0 ? (
        <div
          style={{
            backgroundColor: theme.componentColors.primary.primary,
            color: theme.componentColors.primary.onPrimary,
          }}
          className="rounded-full ml-[10px] p-[4px] w-5 h-5 flex items-center justify-center"
        >
          {currentFilterItems}
        </div>
      ) : (
        ""
      )}
    </Button>
  );
};
