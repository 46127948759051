import { useDispatch, useSelector } from "react-redux";
import { getListings } from "../../api/listings";
import { useEffect } from "react";
import { RootState } from "../../redux/store";
import * as Sentry from "@sentry/nextjs";
import { IUseListings } from "../../interfaces/hooks/IUseListings";
import { setTrainingPlanPage } from "../../redux/pages/exploreSlice";

export const useListings = ({ setIsLoading, setListings }: IUseListings) => {
  const filters = useSelector((state: RootState) => state.filters.listings);
  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoading && setIsLoading(true);
    getListings("", 0)
      .then((resListings) => {
        setListings(resListings);
        dispatch(setTrainingPlanPage(0));
        setIsLoading && setIsLoading(false);
      })
      .catch((e: Error) => {
        console.error(e);
        Sentry.captureException({
          category: "useListings",
          message: "error on getting listings",
          data: e,
        });
      });
  }, [filters.queryStringFilter, dispatch, setIsLoading, setListings]);
};
