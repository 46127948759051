import { IOrganisations } from "../../interfaces/user/IOrganisations";
import { IUserData } from "../../interfaces/user/IUserData";

export function getLastOrganisation(
  coachData: IUserData
): IOrganisations | null {
  let currentExperience: IOrganisations | null = null;

  const organisationsWithoutEndDate = coachData.organisations.filter(
    (organisation) => !organisation.endDate
  );

  if (organisationsWithoutEndDate.length > 1) {
    organisationsWithoutEndDate.sort((a, b) => b.startDate - a.startDate);
  }

  currentExperience = organisationsWithoutEndDate[0] || null;

  if (organisationsWithoutEndDate.length === 0) {
    const coachOrganisationsCopy = [...coachData.organisations];
    const organisationsWithEndDate = coachOrganisationsCopy.sort(
      (a, b) => b.endDate - a.endDate
    );
    currentExperience = organisationsWithEndDate[0] || null;
  }

  return currentExperience;
}
