import { useDispatch } from "react-redux";
import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import { getObjectives } from "../../api/onboarding";
import { IUseObjectives } from "../../interfaces/hooks/IUseObjectives";

export const useObjectives = ({
  setIsLoading,
  setObjectives,
}: IUseObjectives) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoading && setIsLoading(true);
    getObjectives()
      .then((objectives) => {
        setObjectives(objectives);
        setIsLoading && setIsLoading(false);
      })
      .catch((e: Error) => {
        console.error(e);
        Sentry.captureException({
          category: "useObjectives",
          message: "error on getting objectives",
          data: e,
        });
      });
  }, [dispatch, setIsLoading, setObjectives]);
};
