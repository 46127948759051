import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "../../redux/store";
import * as Sentry from "@sentry/nextjs";
import { setTrainingPlanPage } from "../../redux/pages/exploreSlice";
import { IUseCoaches } from "../../interfaces/hooks/IUseCoaches";
import { getSpecialists } from "../../api/coaches";

export const useCoaches = ({ setIsLoading, setCoaches }: IUseCoaches) => {
  const filters = useSelector((state: RootState) => state.filters.coaches);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading && setIsLoading(true);
    getSpecialists("", 0)
      .then((resListings) => {
        setCoaches(resListings);
        dispatch(setTrainingPlanPage(0));
        setIsLoading && setIsLoading(false);
      })
      .catch((e: Error) => {
        console.error(e);
        Sentry.captureException({
          category: "useCoaches",
          message: "error on getting coaches",
          data: e,
        });
      });
  }, [filters.queryStringFilter, dispatch, setIsLoading, setCoaches]);
};
