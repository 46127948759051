import { useDispatch } from "react-redux";
import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import { IUseSports } from "../../interfaces/hooks/IUseSports";
import { getSports } from "../../api/onboarding";

export const useSports = ({ setIsLoading, setSports }: IUseSports) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoading && setIsLoading(true);
    getSports()
      .then((sports) => {
        setSports(sports);
        setIsLoading && setIsLoading(false);
      })
      .catch((e: Error) => {
        console.error(e);
        Sentry.captureException({
          category: "useSports",
          message: "error on getting sports",
          data: e,
        });
      });
  }, [dispatch, setIsLoading, setSports]);
};
