import { useDispatch, useSelector } from "react-redux";
import { getFreeWorkouts } from "../../api/listings";
import { useEffect } from "react";
import { RootState } from "../../redux/store";
import * as Sentry from "@sentry/nextjs";
import { setTrainingPlanPage } from "../../redux/pages/exploreSlice";
import { IUseWorkouts } from "../../interfaces/hooks/IUseWorkouts";

export const useWorkouts = ({ setIsLoading, setWorkouts }: IUseWorkouts) => {
  const filters = useSelector((state: RootState) => state.filters.workouts);
  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoading && setIsLoading(true);
    getFreeWorkouts("", 0)
      .then((resListings) => {
        setWorkouts(resListings);
        dispatch(setTrainingPlanPage(0));
        setIsLoading && setIsLoading(false);
      })
      .catch((e: Error) => {
        console.error(e);
        Sentry.captureException({
          category: "useListings",
          message: "error on getting listings",
          data: e,
        });
      });
  }, [filters.queryStringFilter, dispatch, setIsLoading, setWorkouts]);
};
