import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import DraggableSlider from "../../../global/sliders/DraggableSlider";
import { MOCK_ARRAY_5 } from "../../../../lib/constants";
import { ExploreCoachCard } from "../../../explore/material/cards/exploreCoachCard/ExploreCoachCard";
import Link from "next/link";
import { TextButton } from "../../../global/buttons/material/textButton/TextButton";
import { setTabSection } from "../../../../redux/pages/exploreSlice";
import { CoachesFromApi } from "../../../../interfaces/specialist/CoachesFromApi";
import { HomepageSpecialistLoadingCard } from "../HomepageSpecialistCard";

interface HomepageCoachSliderProps {
  coaches: CoachesFromApi;
  title: string;
  subtitle?: string;
}

export const HomepageCoachSlider = ({
  coaches,
  title,
  subtitle,
}: HomepageCoachSliderProps) => {
  const theme = useSelector((state: RootState) => state.theme);
  const dispatch = useDispatch();

  return (
    <Stack className="space-y-[13px]  lg:space-y-[26px] ">
      <Stack direction="row" className="flex items-center justify-between">
        <Stack>
          <Typography
            variant="h2"
            className="text-3xl font-medium"
            sx={{
              color: theme.componentColors.surface.onSurfaceDetachable,
            }}
          >
            {title}
          </Typography>
          {subtitle ? (
            <Typography
              variant="h3"
              className="text-[15px] font-normal"
              sx={{
                color: theme.componentColors.surface.onSurfaceVariant,
              }}
            >
              {subtitle}
            </Typography>
          ) : (
            <Typography
              variant="h3"
              className="text-[15px] font-normal"
              sx={{
                color: theme.componentColors.surface.onSurfaceVariant,
              }}
            >
              Train like a Pro
            </Typography>
          )}
        </Stack>

        <Link
          href={"/explore"}
          onClick={() => {
            dispatch(setTabSection(1));
          }}
        >
          <TextButton text="See all" />
        </Link>
      </Stack>

      <DraggableSlider
        data={coaches?.rows ? coaches.rows : MOCK_ARRAY_5}
        Component={
          coaches?.rows ? ExploreCoachCard : HomepageSpecialistLoadingCard
        }
        showTitle={false}
        halfPositionArrows
      />
    </Stack>
  );
};
