import React from "react";
import DraggableSlider from "../../global/sliders/DraggableSlider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { MaterialCard } from "../../explore/material/cards/MaterialCard";
import { MOCK_ARRAY_10 } from "../../../lib/constants";
import { MaterialCardLoading } from "../../explore/material/cards/MaterialCardLoading";
import { DefaultLoadingComponent } from "../../global/defaultLoading/DefaultLoadingComponent";
import { Stack, Typography } from "@mui/material";
import Link from "next/link";
import { setTabSection } from "../../../redux/pages/exploreSlice";
import { TextButton } from "../../global/buttons/material/textButton/TextButton";

export const LibraryRecommendedTrainingPlans = () => {
  const { recommendedTrainingPlans, isLoading } = useSelector(
    (state: RootState) => state.library.trainingSection
  );
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const dispatch = useDispatch();

  if (isLoading) {
    return (
      <div className="space-y-3 lg:space-y-6">
        <DefaultLoadingComponent width={250} height={32} />

        <DraggableSlider
          data={MOCK_ARRAY_10}
          Component={MaterialCardLoading}
          showTitle={false}
          isLoading
        />
      </div>
    );
  }

  if (!isLoading && recommendedTrainingPlans.length > 0) {
    return (
      <div className="flex flex-col space-y-3 lg:space-y-6">
        <Stack direction="row" className="flex items-center justify-between">
          <Typography
            variant="h2"
            className="text-2xl font-medium"
            sx={{
              color: componentColors.surface.onSurfaceDetachable,
            }}
          >
            Recommended training plans
          </Typography>

          <Link
            href={"/explore"}
            onClick={() => {
              dispatch(setTabSection(0));
            }}
          >
            <TextButton text="See all" className="p-0 whitespace-nowrap" />
          </Link>
        </Stack>

        <DraggableSlider
          data={recommendedTrainingPlans}
          Component={MaterialCard}
          showTitle={false}
          customSwiperId="user-recommended-training-plans"
          halfPositionArrows
        />
      </div>
    );
  }
};
