import React from "react";

export function HomepageSpecialistLoadingCard() {
  return (
    <div data-cy="homepage-specialist-loading-card">
      <a
        className={` w-72 lg:w-64 mx-2 lg:mx-0" flex items-start justify-center pb-4 bg-black-700 animate-pulse rounded-2xl`}
      >
        <div className={`w-72 lg:w-64 `}>
          <div className="w-full relative">
            <div className=" relative h-36 w-full rounded-2xl bg-gray-300"></div>

            <div className="top-36 left-1/2 flex flex-col items-center justify-center absolute transform -translate-x-1/2 -translate-y-1/2 ">
              <div className="w-28 h-28 bg-gray-100 rounded-full"></div>
            </div>
          </div>
          <div className="flex items-center flex-col mt-12">
            <div className="text-xl font-medium  mt-4 capitalize w-2/4 h-6 bg-black-500 rounded-2xl" />
            <span className="light-gray-chip mt-1 w-1/4 h-7" />
          </div>
        </div>
      </a>
    </div>
  );
}
