import React, { useCallback, useEffect, useState } from "react";

interface UseHeroImagesTranslate {
  ref: React.MutableRefObject<HTMLDivElement>;
}

export const useHeroImagesTranslate = ({ ref }: UseHeroImagesTranslate) => {
  const [translateYPhone, setTranslateYPhone] = useState(0);
  const [isIntersecting, setIsIntersecting] = useState(true);

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    const scrollHeight = document.documentElement.scrollHeight;
    const innerHeight = window.innerHeight;
    const documentHeight = scrollHeight - innerHeight;
    const maxTranslation = 1000;
    const scrollPercentage = scrollTop / documentHeight;
    const newTranslateY =
      maxTranslation * scrollPercentage < 150
        ? maxTranslation * scrollPercentage
        : 150;

    if (isIntersecting) {
      setTranslateYPhone(newTranslateY);
    }
  }, [isIntersecting]);

  useEffect(() => {
    const currentContainer = ref.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((item) => {
        setIsIntersecting(item.isIntersecting);
      });
    });

    if (currentContainer) {
      observer.observe(currentContainer);
    }

    return () => {
      if (currentContainer) {
        observer.unobserve(currentContainer);
      }

      observer.disconnect();
    };
  }, [ref]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return [translateYPhone];
};
