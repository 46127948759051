import { Box, Tabs } from "@mui/material";
import { useSelector } from "react-redux";
import { LibraryTab } from "./LibraryTab";
import { CurrentLibrarySection } from "../../../redux/pages/librarySlice";
import { RootState } from "../../../redux/store";

export const LibraryNavigationTabs = () => {
  const theme = useSelector((state: RootState) => state.theme);
  const librarySection = useSelector(
    (state: RootState) => state.library.currentLibrarySection
  );

  return (
    <Box
      sx={{
        borderBottom: "1px solid",
        borderColor: theme.componentColors.surface.surfContainerHighest,
      }}
    >
      <Tabs
        variant="scrollable"
        scrollButtons={false}
        sx={{
          ".MuiTabs-indicator": {
            backgroundColor: theme.componentColors.primary.primary,
          },
        }}
        value={librarySection}
      >
        <LibraryTab label="Training" value={CurrentLibrarySection.LISTINGS} />
        <LibraryTab label="Coaches" value={CurrentLibrarySection.COACHES} />
      </Tabs>
    </Box>
  );
};
