import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { DefaultLoadingComponent } from "../../global/defaultLoading/DefaultLoadingComponent";
import DraggableSlider from "../../global/sliders/DraggableSlider";
import { MOCK_ARRAY_10 } from "../../../lib/constants";
import { MaterialCardLoading } from "../../explore/material/cards/MaterialCardLoading";
import { LibraryCoach } from "./LibraryCoach";

export const LibraryFollowingCoaches = () => {
  const { followingCoaches, isLoading } = useSelector(
    (state: RootState) => state.library.coachesSection
  );

  const breakpointsObject = {
    250: {
      width: 200,
      slidesPerView: 2,
    },
    300: {
      slidesPerView: 2,
      width: 250,
    },
    800: {
      slidesPerView: 4,
      width: 750,
    },
    1000: {
      slidesPerView: 5,
      width: 950,
    },
    1400: {
      slidesPerView: 5,
      width: 800,
    },
  };

  if (isLoading) {
    return (
      <div className="space-y-3 lg:space-y-6">
        <DefaultLoadingComponent width={250} height={32} />

        <DraggableSlider
          data={MOCK_ARRAY_10}
          Component={MaterialCardLoading}
          showTitle={false}
          isLoading
        />
      </div>
    );
  }

  if (!isLoading && followingCoaches.length > 0) {
    return (
      <DraggableSlider
        data={followingCoaches}
        Component={LibraryCoach}
        showTitle={true}
        title="Following"
        customSwiperId="user-following-coaches"
        showTopButtons
        breakpointsObject={breakpointsObject}
      />
    );
  }
};
