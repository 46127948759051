declare global {
  interface Window {
    dataLayer: {
      push: (arg0: object) => void;
    };
  }
}
export function eventListener(objectData: object) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ...objectData });
}
export const analyticsFunction = (contentSelect: object) => {
  eventListener({ ...contentSelect });
};
