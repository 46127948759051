import React, { useContext } from "react";
import { LandingHeroSectionContext } from "./LandingHeroImagesWebRoot";
import { cn } from "../../../../helpers/cn";

type LandingHeroStripeChipProps = React.ComponentProps<"img">;

export const LandingHeroStripeChip = ({
  className,
}: LandingHeroStripeChipProps) => {
  const { translatePixels } = useContext(LandingHeroSectionContext);

  return (
    <img
      style={{ transform: `translateX(-${translatePixels / 2}px)` }}
      src="/images/stripe-frame.png"
      className={cn(
        " hidden md_2:inline absolute top-1/2 -left-20 z-20 w-[14rem] h-[4rem] object-contain",
        className
      )}
      alt="Stripe Frame | Pod1um"
    />
  );
};
