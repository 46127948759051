import React from "react";

export const HomepageSecondSectionImageScroller = () => {
  return (
    <div className="scroller w-full lg:max-w-[70rem] overflow-x-scroll  scrollbar-hide ">
      <div className="flex space-x-10 scroller__inner">
        <img
          src="https://d2vnlh7fxfujna.cloudfront.net/crests-group-1-1000.png"
          alt="Crests | Pod1um"
          title="Crests | Pod1um"
          className="object-contain"
        />

        <img
          src="https://d2vnlh7fxfujna.cloudfront.net/crests-group-2-1000.png"
          alt="Crests | Pod1um"
          title="Crests | Pod1um"
          className="object-contain"
        />

        <img
          src="https://d2vnlh7fxfujna.cloudfront.net/crests-group-1-1000.png"
          alt="Crests | Pod1um"
          title="Crests | Pod1um"
          className="object-contain"
        />

        <img
          src="https://d2vnlh7fxfujna.cloudfront.net/crests-group-2-1000.png"
          alt="Crests | Pod1um"
          title="Crests | Pod1um"
          className="object-contain"
        />
      </div>
    </div>
  );
};
