import React from "react";
import { IUserData } from "../../../../interfaces/user/IUserData";
import { MaterialCardCoachAvatar } from "./MaterialCardCoachAvatar";
import { Collaborator } from "../../../../interfaces/globalComponents/collaborator/Collaborator";
import { MaterialCardCollaborator } from "./MaterialCardCoachCollaborator";

interface IMaterialCardCoachAvatarCollaborators {
  coach: IUserData;
  collaborators: Collaborator[];
}

export const MaterialCardCoachAvatarCollaborators = ({
  collaborators,
  coach,
}: IMaterialCardCoachAvatarCollaborators) => {
  if (collaborators.length === 0) {
    return <MaterialCardCoachAvatar coach={coach} />;
  }

  if (collaborators.length > 0) {
    return (
      <div className="relative h-full">
        <MaterialCardCoachAvatar coach={coach} />
        {collaborators.map((collaborator, i) => (
          <MaterialCardCollaborator
            key={i}
            position={i + 1}
            collaborator={collaborator}
          />
        ))}
      </div>
    );
  }
};
