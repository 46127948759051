import { Typography } from "@mui/material";
import React from "react";

interface HomepageSecondSectionHeaderProps {
  children: React.ReactNode;
}

export const HomepageSecondSectionHeader = ({
  children,
}: HomepageSecondSectionHeaderProps) => {
  return (
    <Typography
      variant={"h2"}
      className="font-normal text-[20px] lg:text-2xl text-center"
    >
      {children}
    </Typography>
  );
};
