import { useMemo, useState } from "react";

export const useTrainingPlanLevelsTextCard = (levels: string[]) => {
  const [levelsTextCard, setLevelsTextCard] = useState("");

  useMemo(() => {
    if (levels.length === 0) {
      return;
    }

    if (levels.length >= 5) {
      setLevelsTextCard("All levels");
      return;
    }

    const levelsCopy = [...levels];

    setLevelsTextCard(levelsCopy.reverse().join(", "));
  }, [levels]);

  return { levelsTextCard };
};
