import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { ISwiperCustomArrow } from "../../../../../interfaces/globalComponents/sliders/ISwiperCustomArrow";

export const SwiperCustomArrow = ({
  Icon,
  id,
  isHalfTop = true,
}: ISwiperCustomArrow) => {
  const theme = useSelector((state: RootState) => state.theme);

  if (isHalfTop) {
    return (
      <Button
        id={id}
        sx={{
          "&:disabled": {
            opacity: 0.1,
          },
        }}
        className={`items-center justify-center hidden lg:flex
            rounded-full px-0 absolute transform -translate-y-1/2 top-[50%] z-50 min-w-0 w-10 h-10 ${
              id.includes("custom-left-arrow") ? "left-0" : "right-0"
            } `}
      >
        <Icon
          style={{
            color: theme.componentColors.surface.onSurfaceDetachable,
          }}
          className="w-10 h-10"
        />
      </Button>
    );
  }

  return (
    <Button
      id={id}
      variant="contained"
      sx={{
        backgroundColor: theme.componentColors.secondary.secondaryContainer,
        "&:hover": {
          backgroundColor: theme.componentColors.secondary.secondaryContainer,
        },
        "&:disabled": {
          opacity: 0.1,
        },
      }}
      className={`items-center justify-center hidden lg:flex
            rounded-full px-0 min-w-0 w-10 h-10 ${
              id.includes("custom-left-arrow") ? "left-0" : "right-0"
            } `}
    >
      <Icon
        style={{
          color: theme.componentColors.secondary.onSecondaryContainer,
        }}
        className="w-8 h-8"
      />
    </Button>
  );
};
