import { Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Pod1umLogo } from "../../global/logo/Pod1umLogo";
import { OutlinedButton } from "../../global/buttons/material/outlinedButton/OutlinedButton";
import { PrimaryButton } from "../../global/buttons/material/primaryButton/PrimaryButton";
import Link from "next/link";
import { setTabSection } from "../../../redux/pages/exploreSlice";

export const LibraryNoCoaches = () => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const dispatch = useDispatch();

  return (
    <div className="space-y-10 max-w-[28rem]">
      <div className="flex flex-col items-center justify-center space-y-6">
        <div className="w-16">
          <Pod1umLogo />
        </div>

        <div className="space-y-3">
          <Typography
            sx={{
              color: componentColors.background.onBackground,
            }}
            className="text-2xl text-center"
            variant="h3"
          >
            You do not have an active coach
          </Typography>
          <Typography
            sx={{
              color: componentColors.background.onBackground,
            }}
            className="text-center"
          >
            Upgrade your account to unlock training advice and guidance from top
            tier performance coaches.
          </Typography>
        </div>
      </div>

      <div className="flex items-center w-full justify-between space-x-6">
        <Link href="/payment" className="flex-1">
          <OutlinedButton title="Upgrade" className="w-full" />
        </Link>

        <Link
          href="/explore"
          onClick={() => dispatch(setTabSection(1))}
          className="flex-[2]"
        >
          <PrimaryButton title="Explore Coaches" className="w-full" />
        </Link>
      </div>
    </div>
  );
};
