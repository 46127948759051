import { useEffect } from "react";
import {
  getFreeWorkouts,
  getListings,
  getListingsFromWishlist,
  getOwnedListings,
} from "../../api/listings";
import useUser from "../user/useUser";
import { useDispatch } from "react-redux";
import { updateTrainingSection } from "../../redux/pages/librarySlice";

export const useLibraryTrainingsData = () => {
  const { user } = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();

    const getLibraryData = async () => {
      try {
        const data = await Promise.all([
          getOwnedListings(user),
          getFreeWorkouts(),
          getListingsFromWishlist(user.token),
          getListings(),
        ]);

        dispatch(
          updateTrainingSection({
            isLoading: false,
            userTrainingPlans: data[0].rows,
            workouts: data[1].rows,
            bookmarkedTrainingAndWorkouts: data[2].rows,
            recommendedTrainingPlans: data[3].rows,
          })
        );
      } catch (error) {
        console.error("getLibraryDataError", error);
      }
    };

    if (user?.token) {
      void getLibraryData();
    }

    return () => controller.abort();
  }, [dispatch, user]);
};
