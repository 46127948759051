import { useDispatch } from "react-redux";
import { PrimaryButton } from "../../../../buttons/material/primaryButton/PrimaryButton";
import Link from "next/link";
import { setTabSection } from "../../../../../../redux/pages/exploreSlice";

export const FilterSearchButton = () => {
  const dispatch = useDispatch();

  return (
    <Link href="/explore">
      <PrimaryButton
        title="Search"
        onClick={() => dispatch(setTabSection(0))}
        className="px-[33px]"
      />
    </Link>
  );
};
