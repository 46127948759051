import { useDispatch } from "react-redux";
import useUser from "../user/useUser";
import { useEffect } from "react";
import { getOwnedListings } from "../../api/listings";
import { getFollowingCoaches } from "../../api/coaches";
import { updateCoachesSection } from "../../redux/pages/librarySlice";
import { IUserData } from "../../interfaces/user/IUserData";

export const useLibraryCoachesData = () => {
  const { user } = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();

    const getLibraryCoachesData = async () => {
      try {
        const data = await Promise.all([
          getOwnedListings(user),
          getFollowingCoaches(user.token),
        ]);

        const coachesFromOwnedListings = data[0]
          ? data[0].rows.map((item) => item.user)
          : [];

        const coachesFromOwnedListingsUnique = coachesFromOwnedListings.reduce(
          (acc: IUserData[], curr) => {
            if (!acc.find((item) => item.id === curr.id)) {
              acc.push(curr);
            }

            return acc;
          },
          []
        );

        dispatch(
          updateCoachesSection({
            isLoading: false,
            userCoaches: coachesFromOwnedListingsUnique,
            followingCoaches: data[1].rows,
          })
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (user?.token) {
      void getLibraryCoachesData();
    }

    return () => controller.abort();
  }, [dispatch, user]);
};
