import React from "react";
import { HomepageListingSlider } from "../Sliders/HomepageListingSlider";
import { ListingsFromApi } from "../../../../interfaces/training-plan/ListingsFromApi";
import DraggableSlider from "../../../global/sliders/DraggableSlider";
import { MOCK_ARRAY_5 } from "../../../../lib/constants";
import { MaterialCardLoading } from "../../../explore/material/cards/MaterialCardLoading";

interface HomepageTrainingsSliderProps {
  listings: ListingsFromApi;
}

export const HomepageTrainingsSlider = ({
  listings,
}: HomepageTrainingsSliderProps) => {
  if (listings.rows?.length > 0) {
    return (
      <HomepageListingSlider
        listings={listings}
        title="Proven Plans"
        subtitle="Your Roadmap to Success."
        url="/explore"
        exploreTabSection={0}
      />
    );
  }

  return (
    <DraggableSlider
      data={MOCK_ARRAY_5}
      Component={MaterialCardLoading}
      showTitle={false}
      disableArrows
    />
  );
};
