import React from "react";
import { HomepageSecondSectionHeader } from "./HomepageSecondSectionHeader";
import { HomepageSecondSectionWrapper } from "./HomepageSecondSectionWrapper";
import { HomepageSecondSectionImageScroller } from "./HomepageSecondSectionImageScroller";

export function HomepageSecondSection() {
  return (
    <HomepageSecondSectionWrapper>
      <HomepageSecondSectionHeader>
        Coaches trusted by leading teams, athletes and organisations
      </HomepageSecondSectionHeader>

      <HomepageSecondSectionImageScroller />
    </HomepageSecondSectionWrapper>
  );
}
