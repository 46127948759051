import { Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import StarIcon from "@mui/icons-material/Star";

export const MaterialCardScore = ({ score }: { score: number }) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Stack direction={"row"} className="flex items-center space-x-[2px]">
      <StarIcon
        className="w-[16px] h-[16px]"
        sx={{
          color: theme.componentColors.primary.primary,
        }}
      />
      <Typography
        className="text-[14px]"
        sx={{
          color: theme.componentColors.surface.onSurfaceVariant,
        }}
      >
        {score}
      </Typography>
    </Stack>
  );
};
