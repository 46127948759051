import React from "react";

type ActionButtonContainerProps = React.ComponentProps<"div">;

export const ActionButtonContainer = ({
  children,
}: ActionButtonContainerProps) => {
  return (
    <div className="flex items-center justify-center w-full gap-4 md_2:justify-start">
      {children}
    </div>
  );
};
