import React from "react";
import { IBadge } from "../../../interfaces/globalComponents/verificationBadge/IBadge";
import { BadgeOptions } from "../../../enums/BadgeOptions";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenedBadgeModal } from "../../../redux/badgeModalSlice";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Stack } from "@mui/material";
import { RootState } from "../../../redux/store";

export const BadgeVerification = ({
  type,
  clickable = false,
  isFromSpecialist = false,
  isFromTeam = false,
  className,
}: IBadge) => {
  const theme = useSelector((state: RootState) => state.theme);
  const dispatch = useDispatch();

  function showModal() {
    if (clickable) {
      dispatch(setIsOpenedBadgeModal());
    }
  }
  return (
    type != BadgeOptions.unverified && (
      <Stack
        onClick={showModal}
        sx={{
          backgroundColor: theme.componentColors.surface.surfContainerHigh,
        }}
        className={`w-8 h-8 rounded-full flex flex-col items-center justify-center cursor-pointer 
        ${className ? className : ""}`}
      >
        <VerifiedIcon
          sx={{
            color: isFromSpecialist
              ? type == BadgeOptions.pro
                ? "#1EC8FF"
                : "#FF9800"
              : isFromTeam && type == BadgeOptions.verified
              ? "#B6B0A8"
              : "",
          }}
          className={`w-5 h-5`}
        />
      </Stack>
    )
  );
};
