import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { cn } from "../../../helpers/cn";

type LandingHeroTitleProps = React.ComponentProps<"h1">;

export const LandingHeroTitle = ({
  children,
  className,
}: LandingHeroTitleProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <Typography
      sx={{
        color: colors.background.onBackground,
      }}
      variant="h1"
      className={cn(
        "text-5xl  md_2:text-[4.5rem] lg:text-[5rem] font-bold text-center md_2:text-left",
        className
      )}
    >
      {children}
    </Typography>
  );
};
