import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";

export const FilterCheckIcon = () => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <CheckIcon
      sx={{
        color: theme.componentColors.primary.primary,
      }}
    />
  );
};
