import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Typography } from "@mui/material";
import MobileStores from "../../global/MobileStores/MobileStores";

export const LibraryGetTheApp = () => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  return (
    <div className="pb-6">
      <div
        style={{
          backgroundColor: componentColors.surface.surfContainer,
        }}
        className="flex flex-col h-[50rem] w-full lg:flex-row lg:w-full lg:h-[18.75rem] 3xl:h-[30rem]  overflow-hidden rounded-[2rem] "
      >
        <div className="h-1/2 lg:w-1/2 lg:h-full flex flex-col items-center lg:items-start justify-center px-1 lg:pl-8 space-y-12">
          <div className="space-y-4">
            <Typography
              sx={{
                color: componentColors.surface.onSurfaceDetachable,
              }}
              className="font-semibold text-3xl text-center lg:text-start"
            >
              Get the app
            </Typography>

            <Typography
              sx={{
                color: componentColors.surface.onSurface,
              }}
              className="text-base text-center lg:text-start"
            >
              Accelerate your progress from today with proven training plans
              from leading performance specialists.
            </Typography>
          </div>

          <MobileStores useWhiteIcons />
        </div>

        <div className="h-1/2 w-full lg:w-1/2 lg:h-full overflow-hidden relative ">
          <img
            className="object-contain w-full transform scale-[4] lg:scale-150"
            src="./images/bg_library.jpg"
            alt="GET THE APP | Pod1um"
          />
          <img
            className="w-64 absolute top-8 right-1/2 transform translate-x-1/2"
            src="./images/iphone_mockup_phone.png"
            alt="Phone | Pod1um"
          />
        </div>
      </div>
    </div>
  );
};
