import React from "react";
import { CoachesFromApi } from "../../../../interfaces/specialist/CoachesFromApi";
import { HomepageCoachSlider } from "../Sliders/HomepageCoachSlider";
import DraggableSlider from "../../../global/sliders/DraggableSlider";
import { MOCK_ARRAY_5 } from "../../../../lib/constants";
import { MaterialCardLoading } from "../../../explore/material/cards/MaterialCardLoading";

interface HomepageCoachesSectionProps {
  coaches: CoachesFromApi;
}

export const HomepageCoachesSection = ({
  coaches,
}: HomepageCoachesSectionProps) => {
  if (coaches.rows?.length > 0) {
    return (
      <HomepageCoachSlider
        coaches={coaches}
        title="Expert Coaches"
        subtitle="Unleash Your Athletic Potential."
      />
    );
  }

  return (
    <DraggableSlider
      data={MOCK_ARRAY_5}
      Component={MaterialCardLoading}
      showTitle={false}
      disableArrows
    />
  );
};
